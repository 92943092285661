<template>
  <el-dialog
    title="订单详细"
    :close-on-click-modal="false"
    :visible.sync="visible"
    append-to-body
    class="missionPart"
  >
    <div class="order">
      <div class="leftOrder">
        <div class="headTil">订单基本信息</div>
        <div class="containerPart">
          <div class="conList">
            <div class="leftName">订单编号:</div>
            <div class="rightValue">{{ dataForm.id }}</div>
          </div>
          <div class="conList">
            <div class="leftName">订单状态:</div>
            <div class="rightValue">{{ dataForm.stateText }}</div>
          </div>
          <div class="conList">
            <div class="leftName">取餐码:</div>
            <div class="rightValue">{{ dataForm.pickupCode }}</div>
          </div>
          <div class="conList">
            <div class="leftName">下单时间:</div>
            <div class="rightValue">{{ dataForm.paidAt }}</div>
          </div>
        </div>
      </div>

      <div class="middleOrder">
        <div class="headTil">配餐公司信息</div>
        <div class="containerPart">
          <div class="conList">
            <div class="leftName">名称:</div>
            <div class="rightValue">{{ dataForm.orgName }}</div>
          </div>
        </div>
      </div>

      <div class="rightOrder">
        <div class="headTil">订单金额信息</div>
        <div class="containerPart">
          <div class="conList">
            <div class="leftName">支付方式:</div>
            <div class="rightValue">{{ dataForm.payText }}</div>
          </div>
          <div class="conList">
            <div class="leftName">订单总金额:</div>
            <div class="rightValue">
              {{ dataForm.price }}
            </div>
          </div>
        </div>

        <div class="headTil">订单备注信息</div>
        <div class="containerPart">
          <div class="conList">
            <div class="leftName">备注:</div>
            <div class="rightValue">{{ dataForm.remark }}</div>
          </div>
        </div>
      </div>

      <div class="leftOrder">
        <div class="headTil">商品</div>
        <div class="containerPart">
          <div class="block" v-for="(good, index) in goods" :key="index">
            <div class="conList">
              <div class="rightValue">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="good.imageUrl"
                  :fit="good.imageUrl"
                ></el-image>
              </div>
            </div>
            <div class="conList">
              <div class="leftName">名称:</div>
              <div class="rightValue">
                {{
                  good.name +
                  (good.goodsId !== null ? '' : ':' + good.packageCombo)
                }}
              </div>
            </div>
            <div class="conList">
              <div class="leftName">编号:</div>
              <div class="rightValue">{{ good.sn }}</div>
            </div>
            <div class="conList">
              <div class="leftName">单价:</div>
              <div class="rightValue">{{ good.price }}</div>
            </div>
            <div class="conList">
              <div class="leftName">数量:</div>
              <div class="rightValue">{{ good.quantity }}</div>
            </div>
            <div class="conList">
              <div class="leftName">金额:</div>
              <div class="rightValue">{{ good.price }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <span slot="footer" class="dialog-footer"> </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      dataForm: {
        id: '',
        userId: '',
        nickname: '',
        consignee: '',
        mobile: '',
        balance: '',
        wechatPrice: '',
        periodFullName: '',
        subsidyPrice: '',
        daySubsidy: '',
        monthSubsidy: '',
        orderPrice: '',
        periodStartTime: '',
        periodEndTime: '',
        freezerSn: '',
        freezerName: '',
        freezerAddress: '',
        orgName: '',
        idText: '',
        pickupDate: '',
        typeText: '',
        stateText: '',
        payText: '',
        pickupCode: '',
        totalPrice: '',
        actualPrice: '',
        createdAt: '',
        paidAt: '',
        cutOffTime: '',
        remark: '',
        completionTime: '',
        pickupWayText: '',
        sumSubPrice: '',
      },
      goods: [],
    };
  },
  methods: {
    init(data) {
      this.dataForm = data;
      this.visible = true;
      if (this.dataForm.id) {
        this.$http({
          url: `/os/orderDetail/tree/order?orderIds=${this.dataForm.id}`,
          method: 'get',
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.goods = data.orderDetail.item;
          }
        });
      }
    },
  },
};
</script>
<style lang="scss">
.order {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  .leftOrder {
    width: 40%;
    padding: 10px;
    position: relative;
    .headTil {
      font-size: 16px;
      color: #000;
      font-weight: 600;
      line-height: 40px;
      border-bottom: 2px #efefef solid;
      width: 100%;
      height: 40px;
    }
    .containerPart {
      position: relative;
      padding: 10px 0;
      .conList {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        align-items: center;
        //justify-content: center;
        .leftName {
          width: 100px;
          font-size: 14px;
          //color: #000;
          font-weight: 500;
          padding: 0 10px 0 0;
          //text-align: right;
          line-height: 1.8;
        }
        .rightValue {
          flex: 1;
          font-size: 14px;
          color: #666;
          line-height: 1.8;
          img {
            max-width: 100px;
          }
        }
      }
    }
  }
  .middleOrder {
    flex: 1;
    padding: 10px;
    position: relative;
    .headTil {
      font-size: 16px;
      color: #000;
      font-weight: 600;
      line-height: 40px;
      border-bottom: 2px #efefef solid;
      width: 100%;
      height: 40px;
    }
    .containerPart {
      position: relative;
      padding: 10px 0;
      .conList {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        align-items: center;
        //justify-content: center;
        .leftName {
          width: 80px;
          font-size: 14px;
          //color: #000;
          font-weight: 500;
          padding: 0 10px 0 0;
          //text-align: right;
          line-height: 1.8;
        }
        .rightValue {
          flex: 1;
          font-size: 14px;
          color: #666;
          line-height: 1.8;
          img {
            max-width: 100px;
          }
        }
        .inVal {
          display: flex;
          flex-wrap: wrap;
          .thename {
            width: 80px;
            line-height: 36px;
            text-align: center;
          }
        }
      }
    }
  }
  .rightOrder {
    flex: 1;
    padding: 10px;
    position: relative;
    .headTil {
      font-size: 16px;
      color: #000;
      font-weight: 600;
      line-height: 40px;
      border-bottom: 2px #efefef solid;
      width: 100%;
      height: 40px;
    }
    .containerPart {
      position: relative;
      padding: 10px 0;
      .conList {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        align-items: center;
        //justify-content: center;
        .leftName {
          width: 150px;
          font-size: 14px;
          //color: #000;
          font-weight: 500;
          padding: 0 10px 0 0;
          //text-align: right;
          line-height: 1.8;
        }
        .rightValue {
          flex: 1;
          font-size: 14px;
          color: #666;
          line-height: 1.8;
          img {
            max-width: 100px;
          }
        }
        .inVal {
          display: flex;
          flex-wrap: wrap;
          .thename {
            width: 80px;
            line-height: 36px;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>

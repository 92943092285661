<template>
  <div class="base-list-page">
    <el-form
      :model="searchForm"
      @keyup.enter.native="getDataList(1)"
      ref="dataForm"
      inline
      class="bysearchForm"
    >
      <div class="searchLine">
        <el-form-item label="供应商" class="typeInput">
          <el-select v-model="searchForm.orgIds" multiple filterable clearable>
            <el-option
              v-for="(item, index) in orgList"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="日期" class="typeInput">
          <el-date-picker
            v-model="time"
            class="el-popper DatepickerTime"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            clearable
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="订单编号" class="typeInput">
          <el-input
            v-model="searchForm.orderId"
            placeholder="订单编号"
            size="mini"
            clearable
          />
        </el-form-item>
        <el-form-item label="订单状态" class="typeInput">
          <el-select v-model="searchForm.state" filterable clearable multiple>
            <el-option
              v-for="(item, index) in stateList"
              :key="index"
              :label="item.state"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item class="typeInput">
          <el-button @click="getDataList(1)">查询</el-button>
        </el-form-item>
      </div>
    </el-form>
    <el-table
      :data="dataList"
      border
      stripe
      v-loading="dataListLoading"
      ref="dataList"
      @selection-change="selectionChangeHandle"
    >
      <el-table-column
        prop="id"
        header-align="center"
        align="center"
        label="订单编号"
      >
      </el-table-column>
      <el-table-column
        prop="orgName"
        header-align="center"
        align="center"
        label="供应商"
      >
      </el-table-column>
      <el-table-column
        prop="machineName"
        header-align="center"
        align="center"
        label="点餐机"
      >
      </el-table-column>
      <el-table-column
        prop="stateText"
        header-align="center"
        align="center"
        label="状态"
      >
      </el-table-column>
      <el-table-column
        prop="price"
        header-align="center"
        align="center"
        label="订单金额"
      >
      </el-table-column>
      <el-table-column
        prop="payText"
        header-align="center"
        align="center"
        label="支付方式"
      >
      </el-table-column>
      <el-table-column
        prop="createdAt"
        header-align="center"
        align="center"
        label="下单时间"
      >
      </el-table-column>
      <el-table-column
        prop="paidAt"
        header-align="center"
        align="center"
        label="付款时间"
      >
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        width="190"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            v-if="isAuth('tc:orderDetail:info')"
            @click="$dialog('orderDetail', scope.row)"
          >
            详细
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <list-footer>
      <el-pagination
        slot="right"
        class="right"
        background
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper"
      />
    </list-footer>
    <!-- 弹层，仓位管理 -->
    <order-detail
      v-if="orderDetailVisible"
      ref="orderDetail"
      @refreshDataList="getDataList()"
    />
  </div>
</template>

<script>
import orderDetail from './order-detail';
import { listMixin, normal } from '@/mixins';
import { getOrgList } from '@/utils/options.js';
export default {
  mixins: [listMixin, normal],
  data() {
    return {
      orderDetailVisible: false,
      time: [],
      searchForm: {
        name: '',
        pickupWay: '',
        orgIds: [],
        orderId: '',
        dateStart: '',
        dateEnd: '',
        mobile: '',
        state: [],
      },
      prices: {
        totalPrice: 0,
        orderPrice: 0,
        sumSubPrice: 0,
      },
      dataList: [],
      orgList: [],
      stateList: [
        { id: 1, state: '已付款' },
        { id: 5, state: '配餐中' },
        { id: 6, state: '待取餐' },
        { id: 129, state: '已完成' },
        { id: 130, state: '已取消' },
      ],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      addOrUpdateVisible: false,
    };
  },
  components: {
    orderDetail,
  },
  activated() {
    this.getDataList(1);
  },
  created() {
    this.getOrgList();
  },
  methods: {
    // 获取数据列表
    getDataList(page) {
      if (this.time?.length) {
        this.searchForm.dateStart = this.time[0];
        this.searchForm.dateEnd = this.time[1];
      } else {
        this.searchForm.dateStart = '';
        this.searchForm.dateEnd = '';
      }
      this.$http({
        url: '/os/order/list',
        method: 'get',
        params: {
          page: page ? page : this.pageIndex,
          perPage: this.pageSize,
          ...this.searchForm,
          state: this.searchForm.state.join(','),
        },
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.page.records;
          this.totalPage = data.data.total;
          this.prices = data.prices;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
      });
    },
    getOrgList() {
      getOrgList(0, 1).then(({ data }) => {
        if (data && data.status === 0) {
          this.orgList = data.data.items;
        }
      });
    },
  },
};
</script>
